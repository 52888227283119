<template>
  <div class="mb-horarios__lista-horarios">
    <div
      class="mb-horario__container-labels border-b border-gray-300 pb-8 sticky grid grid-cols-3 gap-2 dark:border-gray-700"
    >
      <div>
        <span
          class="uppercase tracking-wider text-xs leading-none text-gray-600 dark:text-gray-400"
          >Salida</span
        >
        <h2 class="leading-tight">{{ estacionSalida?.nom }}</h2>
      </div>
      <div>
        <span
          class="uppercase tracking-wider text-xs leading-none text-gray-600 dark:text-gray-400"
          >Llegada</span
        >
        <h2 class="leading-tight">{{ estacionLlegada?.nom }}</h2>
      </div>
    </div>

    <div class="mb-horario__lista-horas">
      <div
        v-if="isLoadingHorarios"
        class="flex items-center justify-center pt-24"
        role="status"
        aria-label="Cargando horarios"
      >
        <Icon name="line-md:loading-twotone-loop" width="21" height="21" />
      </div>
      <div
        v-else-if="!listaHorarios.length"
        class="text-xl my-20 mb-horario-sinhoras"
        role="status"
        aria-live="polite"
      >
        <em>No hay horarios disponibles a esta hora :(</em>
      </div>
      <TransitionGroup v-else name="horarios" tag="div">
        <HorarioSingle
          :key="`${horario.id}|${horario.S}:${horario.T}`"
          v-for="horario in listaHorarios"
          :horario="horario"
          :estacion-salida="estacionSalida?.nom || 'Salida'"
          :estacion-llegada="estacionLlegada?.nom || 'Llegada'"
          :horarios-vuelta="horariosVuelta"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Horario, IEstacion } from '@/interfaces';
import { horaState } from '@/store/hora';
import { useScheduleFiltering } from '@/composables/useScheduleFiltering';

// Import HorarioSingle type from the component
import HorarioSingle from './HorarioSingle.vue';
type HorarioSingleType = InstanceType<
  typeof HorarioSingle
>['$props']['horario'];

interface Props {
  limite?: number;
  horarios?: Horario[];
  horariosVuelta?: Map<string, HorarioSingleType>;
  estacionSalida?: IEstacion;
  estacionLlegada?: IEstacion;
  fecha?: Date | string;
  isLoadingHorarios?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  limite: 0,
  horarios: () => [],
  horariosVuelta: undefined,
  estacionSalida: undefined,
  estacionLlegada: undefined,
  fecha: new Date().toISOString(),
  isLoadingHorarios: false,
});

const horaBase = horaState();
const fechaActual = computed(() => horaBase.date);
const hora = computed(() => {
  try {
    return new Date(fechaActual.value).toLocaleTimeString('es-CL', {
      hour12: false,
      minute: '2-digit',
      hour: '2-digit',
      timeZone: 'America/Santiago',
    });
  } catch (error) {
    console.error('Error formatting time:', error);
    return '00:00';
  }
});

const { getCurrentDayType, formatTime, getRelativeTime, filterSchedules } =
  useScheduleFiltering();
const dia = computed(() => {
  try {
    return getCurrentDayType(new Date(fechaActual.value));
  } catch (error) {
    console.error('Error getting day type:', error);
    return 'Lu' as const;
  }
});

const listaHorarios = computed<HorarioSingleType[]>(() => {
  try {
    // Basic validation of schedule structure
    const validSchedules = (props.horarios || []).filter((h): h is Horario => {
      if (!h || typeof h.S !== 'string') return false;
      if (!h.id) return false;
      if (!Array.isArray(h.V) || h.V.length === 0) return false;
      return true;
    });

    // Filter and format schedules
    const filteredSchedules = filterSchedules(
      validSchedules,
      hora.value,
      dia.value,
      props.limite
    );

    // Map to final format with time formatting
    return filteredSchedules.map(
      (h): HorarioSingleType => ({
        ...h,
        id: h.id || `${h.idsrv || ''}|${h.S}:${h.T}`,
        rel: getRelativeTime(h.S),
        S: formatTime(h.S),
        T: formatTime(h.T),
        V: h.V,
        ...(h.S2 && { S2: formatTime(h.S2) }),
        ...(h.T2 && { T2: formatTime(h.T2) }),
      })
    );
  } catch (error) {
    console.error('Error processing schedules:', error);
    return [];
  }
});

const horariosVuelta = computed(() => props.horariosVuelta);
</script>

<style>
.horarios-move,
.horarios-enter-active,
.horarios-leave-active {
  transition: all 0.5s ease;
}
.horarios-enter-from,
.horarios-leave-to {
  opacity: 0;
  transform: translateY(60px);
}
.horarios-leave-active {
  position: absolute;
}
</style>
