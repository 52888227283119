<template>
  <div class="mb-servicio__select-container relative">
    <button type="button" class="block py-4 text-left border-b border-grey-light bg-transparent dark:border-gray-700 dark:text-gray-200" data-testid="cambiar-servicio" @click="state.show = !state.show">
      <span class="text-xl leading-none flex items-center">
        <span class="mr-4">{{ (servicio || {}).srv }}</span>
        <Icon
          name="mdi:chevron-down"
          width="24"
          height="24"
          aria-hidden="true"
          :class="[state.show && 'rotate-180', 'transition-all stroke-current text-orange']"
        />
      </span>
    </button>

    <ul v-show="state.show" class="min-h-[150px] bg-white overflow-y-auto border border-grey-lighter shadow absolute z-10 w-full right-0 dark:bg-gray-900 dark:border-gray-700">
      <li v-for="serv in listaServicios.filter((serv) => Number.parseInt(serv._id) > 4)" :key="serv._id" class="flex items-center border-b border-grey-lighter dark:border-gray-700">
        <button type="button" class="flex-1 p-8 text-left" @click="selectServicio(serv._id)">
          {{ serv.srv }}
        </button>
        <button type="button" class="p-8 text-left" aria-label="Destacar estación" @click="starServicio(serv)">
          <Icon
            name="mdi:chevron-down"
            width="16"
            height="16"
            aria-hidden="true"
            :color="serv.destacado ? '#EA9215' : 'transparent'"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { IServicio } from '@/interfaces';

const props = defineProps({
  servicios: {
    type: Array<IServicio & { destacado?: boolean }>,
    default: () => [],
  },
  servicioBase: { type: String },
});
const searchServicio = (s: string) =>
  props.servicios.find((srv) => String(srv._id) === String(s)) ||
  props.servicios[0];

const state = reactive({
  show: false,
  servicio: String(props.servicioBase) || '5',
});

const servicio = computed(
  () => searchServicio(String(state.servicio)) || props.servicios[0]
);

const listaServicios = computed(() =>
  props.servicios.map((srv) =>
    Object.assign({}, srv, {
      destacado: String(props.servicioBase) === String(srv._id),
    })
  )
);

const emit = defineEmits(['star', 'servicio']);

const starServicio = (est: IServicio) => emit('star', est);

const selectServicio = (srv: string) => {
  state.servicio = String(srv);
  state.show = false;

  emit('servicio', servicio.value);
};
</script>
