<template>
  <div class="mb-horarios__compartir mb-24 flex justify-end" v-show="isSharedSupported">
    <button 
      
      type="button"
      class="mb-btn" 
      data-umami-event="share" 
      @click="compartirHorario">
      Compartir Horario
      <Icon name="ion:share-social-sharp" width="16" height="16" aria-hidden="true" />
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useShare } from "@vueuse/core";
const { share, isSupported: isSharedSupported } = useShare();

const props = defineProps({
  salida: [String, Number],
  llegada: [String, Number],
  shareData: {
    type: Object,
    default: () => ({ title: "", text: "", url: "" }),
  },
});

const compartirHorario = () => {
  if (isSharedSupported) {
    return share(props.shareData);
  }
};
</script>
