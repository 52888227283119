import type { Horario } from '@/interfaces';
import { formatDate } from '@/helpers/hora';
import capitalize from 'lodash/capitalize';
import deburr from 'lodash/deburr';

export const DAYS = {
  Lu: 'L-V',
  Ma: 'L-V',
  Mi: 'L-V',
  Ju: 'L-V',
  Vi: 'L-V',
  Sa: 'S',
  Do: 'D',
} as const;

export type DAYS_KEYS = keyof typeof DAYS;

export const useScheduleFiltering = () => {
  const getCurrentDayType = (date: Date): DAYS_KEYS => {
    return deburr(
      capitalize(
        date
          .toLocaleDateString('es-CL', {
            weekday: 'short',
          })
          .slice(0, 2)
      )
    ) as DAYS_KEYS;
  };

  const formatTime = (time: string): string => {
    if (!time) return '00:00';
    if (!/^\d{1,2}:\d{2}/.test(time)) return '00:00';
    return time.replace(/^(\d{2}):(\d{2}):\d{2}/gi, '$1:$2');
  };

  const getRelativeTime = (time: string): string => {
    if (!time) return '00:00';
    if (!/^\d{1,2}:\d{2}/.test(time)) return '00:00';

    const [h, m] = time.split(':');
    const now = new Date();
    const targetDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      Number.parseInt(h, 10),
      Number.parseInt(m, 10)
    );

    const formattedTime = formatDate(targetDate);
    if (!formattedTime) return '00:00';

    return formattedTime.replace(
      /(.+) (\d+) (h|m)?/gi,
      (str: string, $1: string, $2: string, $3: string) => {
        const ext = $3
          ? $3.replace(/horas?/, 'hr').replace(/minutos?/, 'min')
          : '';
        const prefix = $1 ? $1.replace('dentro de', 'en').trim() : '';
        return `${prefix} ${$2} ${ext}`;
      }
    );
  };

  const filterSchedules = (
    schedules: Horario[],
    currentTime: string,
    dayType: DAYS_KEYS,
    limit = 0
  ): Horario[] => {
    if (!Array.isArray(schedules) || !currentTime) return [];

    // First validate time format and basic structure
    const validSchedules = schedules.filter((schedule) => {
      if (!schedule?.S || typeof schedule.S !== 'string') return false;
      if (!Array.isArray(schedule.V) || schedule.V.length === 0) return false;
      if (!/^\d{1,2}:\d{2}/.test(schedule.S)) return false;
      const [hours] = schedule.S.split(':').map(Number);
      if (hours >= 24) return false;
      return true;
    });

    // Sort schedules by time
    const sortedSchedules = [...validSchedules].sort((a, b) => {
      const timeA = new Date(`2020-01-31T${a.S}`).getTime();
      const timeB = new Date(`2020-01-31T${b.S}`).getTime();
      if (Number.isNaN(timeA) || Number.isNaN(timeB)) return 0;
      return timeA - timeB;
    });

    const getHourMinute = (schedule: Horario) => {
      const [scheduleHour, scheduleMinute] = schedule.S.split(':').map(Number);
      const [currentHour, currentMinute] = currentTime.split(':').map(Number);

      return {
        scheduleHour,
        scheduleMinute,
        currentHour,
        currentMinute,
      };
    };

    // Mark first valid schedule and process
    let firstValidFound = false;
    let firstValidHighlighted = false;
    const processedSchedules = sortedSchedules
      .map((schedule) => {
        const { scheduleHour, scheduleMinute, currentHour, currentMinute } =
          getHourMinute(schedule);

        // Compare hours first
        let isScheduleVigente = false;
        if (schedule.V.includes(DAYS[dayType])) {
          if (
            scheduleHour > currentHour ||
            (scheduleHour == currentHour && scheduleMinute >= currentMinute)
          ) {
            isScheduleVigente = true;
            if (!firstValidFound) {
              firstValidFound = true;
            }
          }
        }
        let isFirstObject: Record<string, boolean> = {};
        if (firstValidFound && !firstValidHighlighted) {
          firstValidHighlighted = true;
          isFirstObject = { primero: true };
        }

        return {
          ...schedule,
          ...isFirstObject,
          vigente: isScheduleVigente,
        };
      })
      .filter((schedule) => limit === 0 || schedule.vigente);

    // const getLastAvailableScheduleIndex = sortedSchedules

    return limit > 0 ? processedSchedules.slice(0, limit) : processedSchedules;
  };

  return {
    getCurrentDayType,
    formatTime,
    getRelativeTime,
    filterSchedules,
  };
};
